import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import {
  Select,
  MenuItem,
  Box,
  Chip,
  InputLabel,
  FormControl,
  Typography,
  Tabs,
  Tab,
  Avatar,
  Radio,
  RadioGroup,
  FormControlLabel,
  Slider,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { memo } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { NetworkManager } from "../../Components/NetworkManager";
import { CommonUtil, showToast } from "../../utils/CommonUtil";
import { useProductsContext } from "../../Contexts/SidebarContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { makeStyles, styled } from "@mui/styles";
import ToastManager from "../../Components/ToastManager";
import ImageIcon from "@mui/icons-material/Image";
import EditIcon from "@mui/icons-material/Edit";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const useStyles = makeStyles({
  selectMenu: {
    maxHeight: "160px",
  },
  checkBoxStyles: {
    display: "flex",
    flexWrap: "wrap",
    gap: 0.5,
    marginTop: "0.5vh",
  },
});
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
interface CustomNodeYesProps {
  show: boolean;
  onSaveOrCancelDialogue: Function;
  onCancelDialogue: Function;
  editNode: any;
  onUpdateNode: Function;
  sourceEdge: string;
  deleteButtonLevelEdge: Function;
}

const btnStyle = { marginTop: 10 };
function FormDialog({
  show,
  onSaveOrCancelDialogue,
  onCancelDialogue,
  editNode,
  onUpdateNode,
  sourceEdge,
  deleteButtonLevelEdge,
}: CustomNodeYesProps) {
  const isEdit = Object.keys(editNode).length > 0;
  let hours = null;
  let minutes = null;
  if (isEdit && editNode?.delayCard !== null && editNode?.delayCard > 0) {
    hours = Math.floor(editNode.delayCard / 60);
    minutes = editNode?.delayCard % 60;
  }
  let strMsg: string = editNode.textMessage;
  try {
    if (strMsg.charCodeAt(editNode.textMessage.indexOf("\n")) === 10) {
      strMsg = editNode.textMessage.split("\n").join("\\n");
    }
  } catch (e) {}
  const [selectedRadio, setSelectedRadio] = useState("randomizer");
  const classes = useStyles();
  const initialValues = {
    message: isEdit ? editNode.textMessage : "",
    cardName: isEdit ? editNode.cardName : "",
    genericTitle: isEdit ? editNode.genericTitle : "",
    genericSubTitle: isEdit ? editNode.genericSubTitle : "",
    imageUrl: isEdit ? editNode.imageUrl : "",
    genericChecked: isEdit && editNode.genericChecked ? true : false,
    mediaChecked: isEdit && editNode.mediaChecked ? true : false,
    websiteURL: isEdit ? editNode.websiteUrl : "",
    buttonType: isEdit ? editNode.buttonType : "",
    multipleButton: "",
    checked: (isEdit && hours) || minutes ? true : false,
    hour: isEdit && hours ? hours : "",
    minutes: isEdit && minutes ? minutes : "",
    multiButtons: "",
    isChecked: isEdit && editNode.isChecked ? true : false,
    messengerlist: isEdit ? editNode.subscriberListName : "",
    subscriberName: isEdit ? editNode.subscriberListName : "",
    subscriptionTitle: isEdit ? editNode.optinTitle : "",
    ctaMessage: isEdit ? editNode.ctatext : "",
    supporterCheck: isEdit && editNode.supporterCheck ? true : false,
    detractorCheck: isEdit && editNode.detractorCheck ? true : false,
    randomizer: isEdit && editNode.randomizer ? editNode.randomizer : [],
  };
  const validationSchema = Yup.object().shape({
    isChecked: Yup.boolean(),
    genericChecked: Yup.boolean(),
    mediaChecked: Yup.boolean(),
    message: Yup.string().test(
      "is-required",
      "Please Enter Message",
      function (value) {
        if (
          !this.parent.isChecked ||
          !this.parent.genericChecked ||
          !this.parent.mediaChecked
        ) {
          return Yup.string().min(3, "It's too short").isType(value);
        }
        return true;
      }
    ),
    cardName: Yup.string()
      .min(2, "It's too short")
      .required("Please Enter Card Name"),
    buttonType: Yup.string(),
    hour: Yup.number().max(24),
    minutes: Yup.number().max(59),
    imageUrl: Yup.string().matches(
      /\.(jpg|jpeg|png)$/,
      "Only png and jpg image URLs are allowed"
    ),
    // .required("Image URL is required"),
  });

  const actionFlagsValidationSchema = Yup.object().shape({
    // supporterCheck: Yup.boolean(),
    // detractorCheck: Yup.boolean(),
  });

  const { pageInfo, subscriber, setSubscriber } = useProductsContext();
  const [open, setOpen] = useState(false);
  const [multiButton, setMultiButton] = useState("");
  const [webUrl1, setWebUrl1] = useState("");
  const [buttonType, setButtonType] = useState("");
  const [selected, setSelected] = useState<any>([]);
  const [data, setData] = useState(initialValues);
  const [cardN, setCardN] = useState("");
  const [messageN, setMessageN] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [image, setImage] = useState("");

  const [newFields, setNewFields] = useState(false);
  const [saveList, setSaveList] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [optinCheck, setOptinCheck] = useState(false);
  const [delayCheck, setDelayCheck] = useState(false);
  const [genericCheck, setGenericCheck] = useState(false);
  const [mediaCheck, setMediaCheck] = useState(false);

  const [messengerList, setMessengerList] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [checkedSupporter, setCheckedSupporter] = useState(false);
  const [checkedDetractor, setCheckedDetractor] = useState(false);
  const [fileName, setFileName] = useState("No file chosen");
  const [uploadFile, setUploadFile] = useState(false);
  const [fileSize, setFileSize] = useState(0);
  const [editIndex, setEditIndex] = useState(null);
  const [webUrlSave, setWebUrlSave] = useState(false);
  const [messengerVal, setMessengerVal] = useState("");

  const [validMessage, setValidMessage] = useState(true);
  const [variations, setVariations] = useState(initialValues.randomizer);
  const [error, setError] = useState("");
  const [totalPercentage, setTotalPercentage] = useState(100);
  const [attachId, setAttachId] = useState("");
  const [files, setFiles] = useState("");
  const [uploadAttachId, setUploadAttachId] = useState("");
  const [imgObjKey, setImgObjKey] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpen(show);
    setSelectedTab(0);
    // setCheckedDetractor(false);
    // setCheckedSupporter(false);
    setCardN("");
    setMessageN("");
    setGenericCheck(false);
    setMediaCheck(false);
    setDelayCheck(false);
    setImage("");
    setFileName("No file chosen");
    if (isEdit) {
      if (editNode.cardName === "") {
        setSelectedTab(1);
        // if (!editNode.supporterCheck && !editNode.detractorCheck)
        //   setSelectedRadio("randomizer");
        if (editNode.randomizer) setSelectedRadio("randomizer");
      } else {
        setSelectedTab(0);
      }
      const tempSelectArry = editNode?.multipleButton;
      setSelected(tempSelectArry);
      setButtonType(editNode.buttonType);
      setWebUrl1(editNode.websiteUrl);
      setCardN(editNode.cardName);
      setMessageN(editNode.textMessage);
      setMessengerVal(editNode.subscriberListName);
      setOptinCheck(editNode.isChecked);
      if (editNode.genericTitle) {
        setMediaCheck(false);
      } else setMediaCheck(editNode.mediaChecked);
      setDelayCheck(editNode.checked);
      setGenericCheck(editNode.genericChecked);
      setMediaCheck(editNode.mediaChecked);
      // setCheckedSupporter(editNode.supporterCheck);
      // setCheckedDetractor(editNode.detractorCheck);
      setVariations(editNode.randomizer || []);
      setTitle(editNode.genericTitle);
      setSubTitle(editNode.genericSubTitle);
      setImage(editNode.imageUrl);
      setFileName(editNode.fileName);
    }
  }, [show]);
  useEffect(() => {
    if (Array.isArray(variations)) {
      const totalPer = variations.reduce(
        (acc: any, variation: any) => acc + variation.percentage,
        0
      );
      setTotalPercentage(totalPer);
      if (totalPer !== 100) {
        setError(
          `Distribution total must equal 100%, Current is ${Math.floor(
            totalPer
          )}%`
        );
        setValidMessage(false);
      } else {
        setError("");
        setValidMessage(true);
      }
    }
  }, [variations]);

  const getSubscriberList = () => {
    let params: any = "";
    let pageId = pageInfo?.pageId;
    if (pageId) {
      params = `pageId=${pageId}`;
      try {
        NetworkManager.sendJsonRequest(
          "marketingMessages/getSubscriberLists",
          "",
          params,
          "GET",
          true,
          onSuccessGetList,
          onErrorCall
        );
      } catch (error) {}
    }
  };

  const onSuccessGetList = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (respData) {
      setMessengerList(respData?.subscriberLists);
    }
  };

  const onSaveSubscriberName = (props: any) => {
    let params: any = "";
    let pageId = pageInfo?.pageId;
    if (pageId) {
      params = `pageId=${pageId}`;
      const reqData = {
        subscriberListName: props.values.subscriberName,
        optinTrigger: "private_replies",
        optinTitle: props.values.subscriptionTitle,
        ctatext: props.values.ctaMessage,
      };
      try {
        NetworkManager.sendJsonRequest(
          "marketingMessages/createSubscriberList",
          reqData,
          params,
          "POST",
          true,
          onSuccessSaveList,
          onErrorCall
        );
      } catch (error) {}
    }
  };
  const onSuccessSaveList = (respData: any) => {
    if (respData) {
      setMessengerList(respData?.subscriberLists);
      setNewFields(false);
      getSubscriberList();
      ToastManager.showToast(
        "SubscriberListName Added Successfully.",
        "Success"
      );
    } else if (respData.statusCode === "MKM0009") {
      ToastManager.showToast("SubscriberListName already exists!", "Error");
    } else if (respData.statusCode === "MKM0008") {
      ToastManager.showToast(
        "SubscriberListDetails fields cannot be empty!",
        "Error"
      );
    }
  };
  const onErrorCall = (err: any) => {
    console.log(err);
  };
  //  Upload Attachment
  const onClickUpload = () => {
    if (!image) setLoading(true);
    let pageId = pageInfo?.pageId;
    if (!pageId) {
      ToastManager.showToast("Page ID is required", "Error");
      return;
    }
    if (image) {
      const params = `pageId=${pageId}&imageUrl=${image}`;
      try {
        NetworkManager.sendJsonRequest(
          "uploadAttachment",
          "",
          params,
          "POST",
          true,
          onSuccessUpload,
          onErrorCall
        );
      } catch (error) {
        console.error("Upload with image URL failed", error);
      }
    } else {
      const baseUrl = NetworkManager.serverPath;
      const url1 = `${baseUrl}uploadAttachment?pageId=${encodeURIComponent(
        pageId
      )}`;
      const lToken = localStorage.getItem("cognitoAccessToken");
      const token = lToken;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };
      const formData = new FormData();
      formData.append("file", files);
      if (fileSize > 8 * 1024 * 1024) {
        ToastManager.showToast(
          "File is too large! (It should be less than 8MB)",
          "Error"
        );
        setLoading(false);
      } else
        axios
          .post(url1, formData, config)
          .then((response) => {
            if (response.data.statusCode === "Template2000") {
              setFiles(response.data.imageUrl);
              setUploadAttachId(response.data.attachmentId);
              setImgObjKey(response.data.imageObjectKey);
              ToastManager.showToast(`Image Uploaded Successfully`, "Success");
            } else if (response.data.statusCode === "Template1111") {
              ToastManager.showToast(
                "Error while uploading the attachment!",
                "Error"
              );
            } else {
              ToastManager.showToast("Unexpected error occurred.", "Error");
            }
          })
          .catch((error) => {
            console.error("Upload with file failed", error);
          })
          .finally(() => setLoading(false));
    }
  };
  const onSuccessUpload = (data: any) => {
    var respData = CommonUtil.parseData(data);
    if (fileSize > 8 * 1024 * 1024) {
      ToastManager.showToast(
        "File is too large! (It should be less than 8MB)",
        "Error"
      );
    } else if (respData.statusCode === "Template2000") {
      ToastManager.showToast(`Image Uploaded Successfully`, "Success");
      setAttachId(respData.attachmentId);
    } else if (respData.statusCode === "Template1111") {
      ToastManager.showToast("Error while uploading the attachment!", "Error");
    } else {
      ToastManager.showToast("Unexpected error occurred.", "Error");
    }
  };
  const handleDelete = (i: any, value: any) => {
    setWebUrl1("");
    setWebUrlSave(false);
    let temparr = [...selected];
    temparr.splice(i, 1);
    setSelected(temparr);
    if (isEdit) deleteButtonLevelEdge(value, [editNode]);
  };
  const handleEdit = (i: any, value: any) => {
    if (isEdit) {
      setWebUrlSave(true);
      setEditIndex(i);
      setWebUrl1(value.webUrl);
    }
  };

  const onClickMultiAddButton = () => {
    let id: any = null;
    let isExceededCount: any = false;

    if (
      selected?.length > 0 &&
      selected[selected.length - 1].buttonType !== null
    ) {
      let idNum = parseInt(selected[selected.length - 1].id.substr(-1));

      const getButtonTypesList = selected.filter((selBtn: any) => {
        return (
          selBtn.buttonType === "webUrlButton" ||
          selBtn.buttonType === "postback"
        );
      });

      const getButtonQuickReplies = selected.filter((selBtn: any) => {
        return selBtn.buttonType === "QUICK_REPLIES";
      });
      if (getButtonTypesList != null && getButtonTypesList.length < 3) {
        idNum += 1;
        id = `${buttonType}${idNum}`;
      } else if (
        getButtonQuickReplies != null &&
        getButtonQuickReplies.length > 0 &&
        getButtonQuickReplies.length < 13
      ) {
        idNum += 1;
        id = `${buttonType}${idNum}`;
      } else if (
        getButtonQuickReplies != null &&
        getButtonQuickReplies.length === 0 &&
        buttonType !== "postback" &&
        buttonType !== "webUrlButton"
      ) {
        idNum += 1;
        id = `${buttonType}${idNum}`;
      } else {
        isExceededCount = true;
      }
    } else {
      id = `${buttonType}0`;
    }
    if (!isExceededCount) {
      setSelected((selected: any) => [
        ...selected,
        {
          id: id,
          multiButton: multiButton,
          buttonType: buttonType,
          webUrl: webUrl1,
        },
      ]);
    }

    setMultiButton("");
    setWebUrl1("");
  };
  const handleClose = () => {
    onCancelDialogue();
    setMultiButton("");
    setSelected([]);
    setButtonType("");
    setWebUrl1("");
    setNewFields(false);
    setWebUrlSave(false);
    setVariations([]);
    setImage("");
    if (!isEdit) {
      setFileName("No file chosen");
    }
  };
  const onHandleSave = (value: any) => {
    const updatedData = { ...data, ...value, multipleButton: selected };

    if (isEdit) {
      const nodeInfo = { ...updatedData, label: editNode.label };
      onUpdateNode(nodeInfo);
    } else onSaveOrCancelDialogue(updatedData);
    setData((data) => ({
      ...data,
      ...value,
      multipleButton: selected,
    }));

    setMultiButton("");
    setSelected([]);
    setButtonType("");
    setWebUrl1("");
    setCardN("");
    setMessageN("");
    setMessengerVal("");
    setCheckedDetractor(false);
    setCheckedSupporter(false);
    setVariations([]);
    setOptinCheck(false);
    setDelayCheck(false);
    setGenericCheck(false);
    setMediaCheck(false);
    setTitle("");
    setSubTitle("");
    setImage("");
  };
  const handleSaveEdit = () => {
    if (editIndex !== null) {
      const updatedSelected = [...selected];
      updatedSelected[editIndex].webUrl = webUrl1;
      setSelected(updatedSelected);
      setEditIndex(null);
      //  setWebUrl1("");
      ToastManager.showToast("URL Updated Successfully.", "Success");
    }
  };

  const handleOnMessageUpdate = (e: any) => {
    setMultiButton(e.target.value);
  };

  const handleOnWebUrlChange = (e: any) => {
    setWebUrl1(e.target.value);
  };
  const onSubmit = (values: any, props: any) => {
    if (
      selectedTab === 1 &&
      selectedRadio === "randomizer" &&
      totalPercentage !== 100
    ) {
      ToastManager.showToast(
        "Total Percentage should be equal to 100",
        "Error"
      );
      return;
    }
    let newTempData: any = {
      ...values,
      // supporterCheck:
      //   selectedTab === 0 && selectedRadio === "action"
      //     ? false
      //     : checkedSupporter,
      // detractorCheck:
      //   selectedTab === 0 && selectedRadio === "action"
      //     ? false
      //     : checkedDetractor,
      randomizer:
        selectedTab === 1 && selectedRadio === "randomizer" ? variations : [],
      attachmentId: image ? attachId : "",
      uploadFile: fileName !== "No file chosen" ? files : "",
      UploadAttachmentId: fileName !== "No file chosen" ? uploadAttachId : "",
      fileName: fileName,
      imgObjKey: fileName !== "No file chosen" ? imgObjKey : "",
    };
    try {
      newTempData.message = newTempData.message
        .split("\\n")
        .join(String.fromCharCode(10));
    } catch (e) {}
    onHandleSave(newTempData);
    setWebUrlSave(false);
    if (selectedTab === 0) {
      props.resetForm();
      setNewFields(false);
    }
  };
  const selectedConnection: any = localStorage.getItem("selectedConnection");
  const isTypeExists = (type: string) => {
    if (selectedConnection === "nav-fb-tab") {
      return false;
    } else
      return (
        selected.findIndex((selBtn: any) => selBtn.buttonType === type) !== -1
      );
  };

  const onOpenNewFields = (props: any) => {
    setNewFields(true);
    setSaveList(true);
    setOpenList(false);
    props.setFieldValue("messengerlist", "");
    props.setFieldValue("subscriberName", "");
    props.setFieldValue("subscriptionTitle", "");
    props.setFieldValue("ctaMessage", "");
    setDisableFields(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const switchTab = (tab: any) => {
    if (selectedTab === 0) {
      // setSelectedRadio("randomizer");
      setVariations([]);
      setCheckedSupporter(false);
      setCheckedDetractor(false);
      setTotalPercentage(0);
    } else if (selectedTab === 1) {
      setCardN("");
      setMessageN("");
      setMultiButton("");
      setSelected([]);
      setButtonType("");
      setWebUrl1("");
      setOptinCheck(false);
      setDelayCheck(false);
      setGenericCheck(false);
      setMediaCheck(false);
      setMessengerVal("");
    }
  };
  const handleChangeRadio = (e: any) => {
    setSelectedRadio(e.target.value);
    if (selectedRadio === "action") {
      setVariations([]);
      setTotalPercentage(0);
    } else if (selectedRadio === "randomizer") {
      setCheckedDetractor(false);
      setCheckedSupporter(false);
    }
  };

  const handleKeyPress = (e: any, props: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const target = e.target as HTMLTextAreaElement;
      const cursorPosition = target.selectionStart;
      const newValue = `${target.value.slice(
        0,
        cursorPosition
      )}\n${target.value.slice(cursorPosition)}`;
      setMessageN(newValue);
      props.setFieldValue("message", newValue);
    }
  };
  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        setFileName(file.name);
        setFileSize(file.size);
        setFiles(file);
      } else {
        ToastManager.showToast("Only jpg and png files are allowed!", "Error");
        setFileName("No file chosen");
        setUploadFile(false);
      }
    }
  };
  const handleAddVariation = () => {
    const nextIndex = variations.length;
    if (nextIndex >= 6) return;
    const nextId = `${String.fromCharCode(65 + nextIndex)}${nextIndex + 1}`;

    const newVariation = {
      id: nextId,
      name: `${String.fromCharCode(65 + nextIndex)}`,
      percentage: 100 / (nextIndex + 1),
    };
    // For Decimal Percentage
    const remaining = 100 - Math.floor(100 / (nextIndex + 1)) * (nextIndex + 1);
    const updatedVariations = variations.map((item: any) => ({
      ...item,
      percentage:
        Math.floor(100 / (nextIndex + 1)) + (item < remaining ? 1 : 0),
    }));
    setVariations([...updatedVariations, newVariation]);
  };
  const handleRemoveVariation = (index: any) => {
    const updatedVariations = variations
      .filter((_: any, i: any) => i !== index)
      .map((variation: any, id: any) => ({
        ...variation,
        name: String.fromCharCode(65 + id),
      }));
    const length = updatedVariations.length;
    const basePercentage = Math.floor(100 / length);
    const remaining = 100 - basePercentage * length;

    const adjustedVariations = updatedVariations.map(
      (variation: any, id: any) => ({
        ...variation,
        percentage: basePercentage + (id < remaining ? 1 : 0),
      })
    );
    setVariations(adjustedVariations);
  };

  const handleSliderChange = (index: any, newValue: any) => {
    const updatedVariations = [...variations];
    updatedVariations[index].percentage = Math.floor(newValue);
    setVariations(updatedVariations);
  };

  return (
    <div>
      <Dialog
        open={open}
        PaperProps={{
          sx: { width: "40%", maxHeight: "70%" },
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="tabs"
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          sx={{
            "& .Mui-selected": {
              bgcolor: "#1976d2",
              color: "#ffffff !important",
            },
            "& .MuiTab-root:not(.Mui-selected)": {
              border: "1px solid #ccc",
              borderColor: "#ccc",
            },
          }}
        >
          <Tab
            label="Card Info"
            onClick={() => switchTab(0)}
            sx={{ textTransform: "none", fontSize: "16px" }}
            disabled={
              isEdit && selectedRadio === "randomizer" && totalPercentage > 0
                ? true
                : false
            }
          />
          <Tab
            // label="Action Flags / AB Test"
            label="A/B Test"
            // disabled={sourceEdge === "nextStep" ? true : false}
            onClick={() => switchTab(1)}
            sx={{ textTransform: "none", fontSize: "16px" }}
          />
        </Tabs>
        <DialogTitle>
          {selectedTab === 0 ? (
            "Card Information"
          ) : (
            <FormControl sx={{ ml: 1.5 }}>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={selectedRadio}
                onChange={handleChangeRadio}
              >
                <FormControlLabel
                  value="randomizer"
                  control={<Radio />}
                  label="A/B Test"
                  // sx={{ ml: 3 }}
                />
                <FormControlLabel
                  value="action"
                  control={<Radio />}
                  label="Action"
                  sx={{ ml: 3 }}
                  disabled
                  // disabled={
                  //   isEdit && selectedRadio === "randomizer" ? true : false
                  // }
                />
              </RadioGroup>
            </FormControl>
          )}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={
              selectedTab === 0 ? validationSchema : actionFlagsValidationSchema
            }
            onSubmit={onSubmit}
          >
            {(props) => (
              <Form>
                {selectedTab === 0 && (
                  <Box>
                    <Box sx={{ display: "flex" }}>
                      <Field
                        as={TextField}
                        name="cardName"
                        label="Card Name"
                        fullWidth
                        autoComplete="off"
                        error={props.errors.cardName && props.touched.cardName}
                        helpertext={<ErrorMessage name="cardName" />}
                        required
                        size="small"
                        sx={{ height: 40, mt: "1vh" }}
                        value={cardN}
                        onChange={(e: any) => {
                          setCardN(e.target.value);
                          props.setFieldValue("cardName", e.target.value);
                        }}
                      />
                    </Box>
                    {subscriber == "subscriber" ? (
                      <Box sx={{ display: "flex" }}>
                        <Box
                          className={classes.checkBoxStyles}
                          // sx={{ ml: 3 }}
                        >
                          <Field
                            as={Checkbox}
                            label="Media Template"
                            type="checkbox"
                            name="mediaChecked"
                            checked={mediaCheck}
                            onChange={(e: any) => {
                              setMediaCheck(e.target.checked);
                              props.setFieldValue(
                                "mediaChecked",
                                e.target.checked
                              );
                              if (e.target.checked) {
                                setGenericCheck(false);
                                setImage("");
                              }
                            }}
                          />
                          <InputLabel sx={{ mt: "1.5vh" }}>
                            Media Template
                          </InputLabel>
                        </Box>
                        <Box className={classes.checkBoxStyles} sx={{ ml: 3 }}>
                          <Field
                            as={Checkbox}
                            label="Generic Template"
                            type="checkbox"
                            name="genericChecked"
                            checked={genericCheck}
                            onChange={(e: any) => {
                              setGenericCheck(e.target.checked);
                              props.setFieldValue(
                                "genericChecked",
                                e.target.checked
                              );
                              if (!props.values.genericChecked) {
                                setTitle("");
                                setSubTitle("");
                                setImage("");
                              }
                              if (e.target.checked) {
                                setMediaCheck(false);
                                setFileName("");
                                setImage(image);
                              }
                            }}
                          />
                          <InputLabel sx={{ mt: "1.5vh" }}>
                            Generic Template
                          </InputLabel>
                        </Box>
                      </Box>
                    ) : (
                      ""
                    )}
                    {props.values.isChecked ? null : (
                      <Box>
                        {props.values.genericChecked ||
                        props.values.mediaChecked ? (
                          ""
                        ) : (
                          <Box>
                            <Field
                              as={TextField}
                              name="message"
                              label="Message"
                              fullWidth
                              autoComplete="off"
                              error={
                                props.errors.message && props.touched.message
                              }
                              //   helpertext={<ErrorMessage name="message" />}
                              required
                              multiline
                              size="small"
                              sx={{
                                height: "auto",
                                mt: "2vh",
                                mb: 0.5,
                              }}
                              inputProps={{
                                maxLength: buttonType !== "" ? 640 : 2000,
                              }}
                              helperText={
                                buttonType !== ""
                                  ? `Message must be less than 640 characters long (${props.values.message.length}/640)`
                                  : `Message must be less than 2000 characters long (${props.values.message.length}/2000)`
                              }
                              value={messageN}
                              onChange={(e: any) => {
                                const newMessage = e.target.value;
                                setMessageN(newMessage);
                                props.setFieldValue("message", newMessage);
                              }}
                              onKeyPress={(
                                e: React.KeyboardEvent<HTMLTextAreaElement>
                              ) => handleKeyPress(e, props)}
                            />
                            <ErrorMessage name="message" />
                          </Box>
                        )}
                        {/* Media Template start */}
                        {props.values.mediaChecked && genericCheck === false ? (
                          <Box sx={{ mt: 2 }}>
                            <Box className="image-upload">
                              <Box
                                sx={{
                                  display: props.values.isChecked
                                    ? "none"
                                    : "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  component="label"
                                  variant="contained"
                                  size="small"
                                  startIcon={<ImageIcon />}
                                  sx={{
                                    textTransform: "none",
                                    backgroundColor: "#4aa5dc",
                                    "&:hover": {
                                      backgroundColor: "#4aa5dc",
                                    },
                                    width: "120px",
                                  }}
                                  disabled={image ? true : false}
                                >
                                  Choose file
                                  <VisuallyHiddenInput
                                    type="file"
                                    onChange={handleFileChange}
                                  />
                                </Button>
                                <InputLabel
                                  sx={{
                                    marginTop: "1.5vh",
                                    ml: 2,
                                    fontSize: "14px",
                                  }}
                                >
                                  {fileName !== "No file chosen"
                                    ? ""
                                    : "No file chosen"}
                                </InputLabel>
                              </Box>
                              <Box>
                                {loading ? (
                                  <CircularProgress
                                    size={40}
                                    sx={{ color: "#4aa5dc" }}
                                  />
                                ) : (
                                  <Button
                                    component="label"
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      ml: 10,
                                      textTransform: "none",
                                      color: "#4aa5dc",
                                      padding: "4px 25px",
                                      display: props.values.isChecked
                                        ? "none"
                                        : "block",
                                    }}
                                    onClick={onClickUpload}
                                    disabled={
                                      !image && fileName === "No file chosen"
                                        ? true
                                        : false
                                    }
                                  >
                                    Upload Attachment
                                  </Button>
                                )}
                              </Box>
                            </Box>
                            {fileName !== "No file chosen" && (
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <InputLabel
                                  sx={{
                                    mt: "1vh",
                                    fontSize: "14px",
                                  }}
                                >
                                  {fileName}
                                </InputLabel>
                                <Typography
                                  sx={{
                                    cursor: "pointer",
                                    ml: 2,
                                    mt: 0.5,
                                    display: fileName ? "block" : "none",
                                  }}
                                  onClick={() => {
                                    setFileName("No file chosen");
                                    setImage("");
                                  }}
                                >
                                  x
                                </Typography>
                              </Box>
                            )}
                            <Typography
                              sx={{ textAlign: "center", fontSize: "12px" }}
                            >
                              (OR)
                            </Typography>
                          </Box>
                        ) : (
                          ""
                        )}
                        {/* Media Template end */}
                        {/* Generic Template start */}
                        {props.values.genericChecked && mediaCheck === false ? (
                          <Box>
                            <Box sx={{ display: "flex", mt: "2vh" }}>
                              <Field
                                as={TextField}
                                name="genericTitle"
                                label="Title"
                                // fullWidth
                                autoComplete="off"
                                error={
                                  props.errors.genericTitle &&
                                  props.touched.genericTitle
                                }
                                helpertext={
                                  <ErrorMessage name="genericTitle" />
                                }
                                required={
                                  props.values.genericChecked ? true : false
                                }
                                size="small"
                                sx={{ height: 40, width: "85%" }}
                                inputProps={{ maxLength: 80 }}
                                value={title}
                                onChange={(e: any) => {
                                  setTitle(e.target.value);
                                  props.setFieldValue(
                                    "genericTitle",
                                    e.target.value
                                  );
                                }}
                              />
                              <Typography
                                sx={{ ml: 2, mt: 1 }}
                              >{`(${title.length}/80)`}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", mt: "2vh" }}>
                              <Field
                                as={TextField}
                                name="genericSubTitle"
                                label="Sub Title"
                                fullWidth
                                autoComplete="off"
                                error={
                                  props.errors.genericSubTitle &&
                                  props.touched.genericSubTitle
                                }
                                helpertext={
                                  <ErrorMessage name="genericSubTitle" />
                                }
                                size="small"
                                sx={{ height: 40, width: "85%" }}
                                inputProps={{ maxLength: 80 }}
                                value={subTitle}
                                onChange={(e: any) => {
                                  setSubTitle(e.target.value);
                                  props.setFieldValue(
                                    "genericSubTitle",
                                    e.target.value
                                  );
                                }}
                              />
                              <Typography
                                sx={{ ml: 2, mt: 1 }}
                              >{`(${subTitle.length}/80)`}</Typography>
                            </Box>
                          </Box>
                        ) : (
                          ""
                        )}
                        {props.values.mediaChecked ||
                        props.values.genericChecked ? (
                          <Box>
                            <Field
                              as={TextField}
                              name="imageUrl"
                              label="Image Url"
                              fullWidth
                              autoComplete="off"
                              error={
                                props.errors.imageUrl && props.touched.imageUrl
                              }
                              helpertext={<ErrorMessage name="imageUrl" />}
                              size="small"
                              required={
                                genericCheck === true || mediaCheck === true
                                  ? true
                                  : false
                              }
                              sx={{
                                height: 40,
                                mt: "2vh",
                                mb: "1vh",
                              }}
                              value={image}
                              onChange={(e: any) => {
                                const url = e.target.value;
                                setImage(url);
                                props.setFieldValue("imageUrl", url);
                              }}
                              disabled={
                                fileName !== "No file chosen" &&
                                fileName !== undefined &&
                                fileName !== ""
                                  ? true
                                  : false
                              }
                            />
                            <ErrorMessage name="imageUrl" />
                            {mediaCheck &&
                              !(
                                props.errors.imageUrl && props.touched.imageUrl
                              ) && (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  sx={{ fontSize: "12px" }}
                                >
                                  Note: After adding the URL, please Upload
                                  Attachment.
                                </Typography>
                              )}
                          </Box>
                        ) : (
                          ""
                        )}
                        {/* Generic Template end */}
                        <div
                          style={{
                            height: 45,
                            marginTop: "1vh",
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel>Button Type</InputLabel>
                            <Select
                              // as={Select}
                              name="buttonType"
                              label="Button Type"
                              size="small"
                              onChange={(e: any) => {
                                setButtonType(e.target.value);
                                props.setFieldValue(
                                  "buttonType",
                                  e.target.value
                                );
                              }}
                              value={
                                props.values.buttonType !== ""
                                  ? props.values.buttonType
                                  : ""
                              }
                              sx={{ height: 45 }}
                            >
                              <MenuItem value={""}>Select</MenuItem>
                              <MenuItem
                                value={"postback"}
                                disabled={isTypeExists("QUICK_REPLIES")}
                              >
                                Postback
                              </MenuItem>
                              <MenuItem
                                value={"webUrlButton"}
                                disabled={isTypeExists("QUICK_REPLIES")}
                              >
                                Web URL
                              </MenuItem>
                              <MenuItem
                                value={"QUICK_REPLIES"}
                                disabled={
                                  isTypeExists("webUrlButton") ||
                                  isTypeExists("postback")
                                }
                              >
                                Quick Replies
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              display: `${
                                buttonType === "webUrlButton" || webUrlSave
                                  ? "block"
                                  : "none"
                              }`,
                              width: "100%",
                            }}
                          >
                            <Field
                              as={TextField}
                              name="websiteURL"
                              label={webUrlSave ? "" : "Website URL"}
                              fullWidth
                              autoComplete="off"
                              error={
                                props.errors.websiteURL &&
                                props.touched.websiteURL
                              }
                              helpertext={<ErrorMessage name="websiteURL" />}
                              // required
                              size="small"
                              sx={{ height: 40, mt: "2vh" }}
                              value={webUrl1}
                              onChange={handleOnWebUrlChange}
                            />
                          </div>
                          {webUrlSave ? (
                            <Button
                              sx={{ mt: 2, width: "5%" }}
                              onClick={handleSaveEdit}
                            >
                              Update
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "2vh",
                          }}
                        >
                          <Field
                            as={TextField}
                            name="multiButtons"
                            label="Multiple Button"
                            fullWidth
                            autoComplete="off"
                            error={
                              props.values.buttonType !== ""
                                ? multiButton === "" &&
                                  multiButton.trim() === ""
                                  ? true
                                  : false
                                : false
                            }
                            helpertext={<ErrorMessage name="multiButtons" />}
                            //   required={isEdit ? false : true}
                            size="small"
                            value={multiButton !== "" ? multiButton : ""}
                            onChange={handleOnMessageUpdate}
                            sx={{ height: 40 }}
                          />
                          <button
                            type="button"
                            className="plus alt"
                            onClick={onClickMultiAddButton}
                            disabled={
                              buttonType !== "" &&
                              multiButton !== "" &&
                              multiButton.trim() !== ""
                                ? false
                                : true
                            }
                          >
                            +
                          </button>
                        </div>

                        <Box
                          className={classes.checkBoxStyles}
                          sx={{ mt: "1.5vh !important" }}
                        >
                          {selected.map((value: any, i: any) => (
                            <Chip
                              key={i}
                              label={value.multiButton}
                              avatar={
                                <Avatar
                                  sx={{
                                    display:
                                      value.buttonType === "webUrlButton"
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  <EditIcon
                                    fontSize="small"
                                    onClick={() => handleEdit(i, value)}
                                  />
                                </Avatar>
                              }
                              onDelete={() => handleDelete(i, value)}
                              deleteIcon={<DeleteIcon />}
                              variant="outlined"
                              color={
                                value.buttonType === "postback"
                                  ? "primary"
                                  : value.buttonType === "webUrlButton"
                                  ? "secondary"
                                  : "success"
                              }
                            />
                          ))}
                        </Box>
                        {buttonType !== "" ? (
                          <div
                            style={{
                              display: "flex",
                              columnGap: "10px",
                              justifyContent: "center",
                              marginTop: "15px",
                            }}
                          >
                            {" "}
                            <Typography color={"primary"}>Postback</Typography>
                            <Typography color={"secondary"}>Web URL</Typography>
                            <Typography
                              sx={{ color: "rgba(46, 125, 50, 0.7)" }}
                            >
                              Quick Reply
                            </Typography>
                          </div>
                        ) : (
                          ""
                        )}
                      </Box>
                    )}

                    <Box sx={{ display: "flex" }}>
                      <Box className={classes.checkBoxStyles}>
                        <Field
                          as={Checkbox}
                          label="Delay Card?"
                          type="checkbox"
                          name="checked"
                          checked={delayCheck}
                          onChange={(e: any) => {
                            setDelayCheck(e.target.checked);
                            props.setFieldValue("checked", e.target.checked);
                          }}
                          disabled={sourceEdge === "nextStep" ? false : true}
                        />
                        <InputLabel sx={{ mt: "1.5vh" }}>
                          Delay Card?
                        </InputLabel>
                      </Box>
                      {selectedConnection === "nav-fb-tab" ? (
                        subscriber == "subscriber" ? (
                          ""
                        ) : (
                          <Box
                            className={classes.checkBoxStyles}
                            sx={{ ml: "50px" }}
                          >
                            <Field
                              as={Checkbox}
                              label="Include Opt-in"
                              type="checkbox"
                              name="isChecked"
                              checked={optinCheck}
                              onChange={(e: any) => {
                                setOptinCheck(e.target.checked);
                                props.setFieldValue(
                                  "isChecked",
                                  e.target.checked
                                );
                              }}
                            />
                            <InputLabel sx={{ mt: "1.5vh" }}>
                              Include Opt-in
                            </InputLabel>
                          </Box>
                        )
                      ) : (
                        ""
                      )}
                    </Box>
                    {props.values.checked ? (
                      <Box
                        className={classes.checkBoxStyles}
                        sx={{ gap: "4px !important" }}
                      >
                        <Field
                          as={TextField}
                          name="hour"
                          label="Hours"
                          size="small"
                          type="number"
                          inputProps={{
                            max: 24,
                            min: 0,
                          }}
                          helpertext={<ErrorMessage name="hour" />}
                          sx={{
                            height: 40,
                            width: "40%",
                          }}
                        />
                        <ErrorMessage name="hour" />
                        <Field
                          as={TextField}
                          name="minutes"
                          label="Minutes"
                          size="small"
                          sx={{
                            height: 40,

                            width: "40%",
                          }}
                          inputProps={{
                            min: 1,
                            max: 59,
                          }}
                          type="number"
                          helpertext={<ErrorMessage name="minutes" />}
                        />
                        <ErrorMessage name="minutes" />
                      </Box>
                    ) : null}

                    {props.values.isChecked ? (
                      <Box>
                        <Box>
                          <div style={{ height: 40, marginTop: "2vh" }}>
                            <FormControl fullWidth>
                              <InputLabel>Messenger List</InputLabel>
                              <Select
                                name="messengerlist"
                                label="Messenger List"
                                size="small"
                                open={openList}
                                onClose={() => setOpenList(false)}
                                onOpen={() => {
                                  if (subscriber !== "subscriber") {
                                    getSubscriberList();
                                  }
                                  setOpenList(true);
                                }}
                                onChange={(e: any) => {
                                  const selectedValue = e.target.value;
                                  const selectedObject = messengerList.find(
                                    (item: any) =>
                                      item.subscriberListName === selectedValue
                                  );
                                  if (selectedObject) {
                                    props.setFieldValue(
                                      "messengerlist",
                                      selectedValue
                                    );
                                    setMessengerVal(selectedValue);
                                    setNewFields(true);
                                    setSaveList(false);
                                    setOpenList(false);
                                    props.setFieldValue(
                                      "subscriberName",
                                      selectedObject.subscriberListName
                                    );
                                    props.setFieldValue(
                                      "subscriptionTitle",
                                      selectedObject.optinTitle
                                    );
                                    props.setFieldValue(
                                      "ctaMessage",
                                      selectedObject.ctatext
                                    );
                                    setDisableFields(true);
                                  }
                                  if (isEdit) setNewFields(true);
                                }}
                                value={messengerVal}
                                MenuProps={{
                                  classes: { paper: classes.selectMenu },
                                }}
                                sx={{
                                  height: 45,
                                }}
                              >
                                {messengerList?.map((item: any, index: any) => (
                                  <MenuItem
                                    key={index}
                                    value={item?.subscriberListName}
                                  >
                                    {item?.subscriberListName}
                                  </MenuItem>
                                ))}
                                <hr />
                                <MenuItem
                                  sx={{ color: "#1976d2", ml: 28 }}
                                  onClick={() => onOpenNewFields(props)}
                                >
                                  <AddCircleOutlineIcon
                                    fontSize="small"
                                    sx={{ mr: 0.5 }}
                                  />
                                  Create
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </Box>

                        {newFields ? (
                          <Box>
                            <Box>
                              <Field
                                as={TextField}
                                name="subscriberName"
                                label="Subscriber Name"
                                fullWidth
                                autoComplete="off"
                                disabled={disableFields}
                                error={
                                  props.errors.subscriberName &&
                                  props.touched.subscriberName
                                }
                                helpertext={
                                  <ErrorMessage name="subscriberName" />
                                }
                                required
                                size="small"
                                sx={{
                                  height: 45,
                                  mt: "2vh",
                                }}
                              />
                              {/* <ErrorMessage name="subscriberName" /> */}
                            </Box>
                            <Box>
                              <Field
                                as={TextField}
                                name="subscriptionTitle"
                                label="Subscription Title"
                                fullWidth
                                autoComplete="off"
                                disabled={disableFields}
                                error={
                                  props.errors.subscriptionTitle &&
                                  props.touched.subscriptionTitle
                                }
                                helpertext={
                                  <ErrorMessage name="subscriptionTitle" />
                                }
                                required
                                size="small"
                                sx={{
                                  height: 45,
                                  mt: "2vh",
                                }}
                              />
                              {/* <ErrorMessage name="subscriptionTitle" /> */}
                            </Box>
                            <Box sx={{ display: "flex" }}>
                              <div
                                style={{
                                  height: 45,
                                  marginTop: "2vh",
                                  width: saveList ? "80%" : "100%",
                                }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel>CTA Messages</InputLabel>
                                  <Select
                                    // as={Select}
                                    name="ctaMessage"
                                    label="CTA Message"
                                    size="small"
                                    disabled={disableFields}
                                    onChange={(e: any) => {
                                      props.setFieldValue(
                                        "ctaMessage",
                                        e.target.value
                                      );
                                    }}
                                    value={
                                      props.values.ctaMessage !== ""
                                        ? props.values.ctaMessage
                                        : ""
                                    }
                                    sx={{
                                      height: 45,
                                    }}
                                  >
                                    <MenuItem value="GET_UPDATES">
                                      Get updates
                                    </MenuItem>
                                    <MenuItem value="ALLOW">
                                      Allow messages
                                    </MenuItem>
                                    <MenuItem value="GET">
                                      Get messages
                                    </MenuItem>
                                    <MenuItem value="OPT_IN">
                                      Opt in to messages
                                    </MenuItem>
                                    <MenuItem value="SIGN_UP">
                                      Sign up for messages
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                              {saveList ? (
                                <div
                                  style={{
                                    width: "20%",
                                    marginTop: "16px",
                                    marginLeft: "25px",
                                  }}
                                >
                                  <Button
                                    onClick={() => onSaveSubscriberName(props)}
                                  >
                                    Save List
                                  </Button>
                                </div>
                              ) : null}
                            </Box>
                          </Box>
                        ) : null}
                      </Box>
                    ) : null}
                    <div
                      style={{
                        padding: "4px",
                        display: `${
                          buttonType === "QUICK_REPLIES" ? "none" : "block"
                        }`,
                      }}
                    ></div>
                  </Box>
                )}
                {selectedTab === 1 && (
                  <div>
                    {selectedRadio === "randomizer" && (
                      <Box>
                        <Box sx={{ ml: 1.5 }}>
                          {validMessage && (
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Split the traffic{" "}
                              <span style={{ fontWeight: 400 }}>
                                (Distribution total must equal 100%)
                              </span>
                            </Typography>
                          )}
                          {totalPercentage !== 100 ? (
                            <Typography sx={{ color: "#ff0000" }}>
                              {error}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Box
                          className={classes.checkBoxStyles}
                          sx={{ mt: "10px !important" }}
                        >
                          <Field
                            as={Checkbox}
                            type="checkbox"
                            name="supporterCheck"
                            checked
                          />
                          <InputLabel sx={{ marginTop: "1vh" }}>
                            Random path every time{" "}
                            <HelpOutlineIcon fontSize="small" />
                          </InputLabel>
                        </Box>
                        <Box>
                          {variations.map((item: any, index: any) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                ml: 1.5,
                                p: 0.75,
                              }}
                            >
                              <Typography>{item.name}</Typography>
                              <Slider
                                value={Math.floor(item.percentage)}
                                onChange={(e: any, newValue: any) =>
                                  handleSliderChange(index, newValue)
                                }
                                aria-labelledby={`variation-${item.name}`}
                                valueLabelDisplay="auto"
                                step={1}
                                min={0}
                                max={100}
                                sx={{ mx: 2, flexGrow: 1 }}
                              />
                              <Typography>
                                {Math.floor(item.percentage)}%
                              </Typography>
                              <IconButton
                                aria-label="delete"
                                onClick={() => handleRemoveVariation(index)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          ))}
                        </Box>
                        <Box sx={{ textAlign: "center" }}>
                          {
                            <Button
                              variant="contained"
                              onClick={handleAddVariation}
                              sx={{ mt: 2 }}
                              disabled={variations.length >= 6 ? true : false}
                            >
                              + New Variation
                            </Button>
                          }
                        </Box>
                      </Box>
                    )}
                    {selectedRadio === "action" && (
                      <Box sx={{ height: "180px" }}>
                        <Box
                          className={classes.checkBoxStyles}
                          sx={{ mt: "1vh !important" }}
                        >
                          <Field
                            as={Checkbox}
                            label="Supporter"
                            type="checkbox"
                            name="supporterCheck"
                            // disabled={sourceEdge === "nextStep" ? true : false}
                            checked={checkedSupporter}
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                setCheckedSupporter(true);
                                setCheckedDetractor(false);
                              } else {
                                setCheckedSupporter(false);
                              }
                            }}
                          />
                          <InputLabel sx={{ mt: "1.5vh", fontSize: "18px" }}>
                            Supporter
                          </InputLabel>
                        </Box>
                        <Box
                          className={classes.checkBoxStyles}
                          sx={{ mt: "1vh !important" }}
                        >
                          <Field
                            as={Checkbox}
                            label="Detractor"
                            type="checkbox"
                            name="detractorCheck"
                            // disabled={sourceEdge === "nextStep" ? true : false}
                            checked={checkedDetractor}
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                setCheckedDetractor(true);
                                setCheckedSupporter(false);
                              } else {
                                setCheckedDetractor(false);
                              }
                            }}
                          />
                          <InputLabel sx={{ mt: "1.5vh", fontSize: "18px" }}>
                            Detractor
                          </InputLabel>
                        </Box>
                      </Box>
                    )}
                  </div>
                )}
                <Button type="submit" style={btnStyle}>
                  {isEdit ? "Update" : "Save"}
                </Button>
                <Button style={btnStyle} onClick={handleClose}>
                  Cancel
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default memo(FormDialog);

//onClick={onSubmit}
